import RouteView from '@/views/layout/routerView.vue'

export default {
	path: 'property',
	name: 'property',
	component: RouteView,
	children: [
		// 设备维保 >> 保养计划
		{
			path: 'maintenanceSchedule',
			name: 'maintenanceSchedule',
			component: () =>
				import('@/views/propertyManage/equipmentMaintenance/maintenanceSchedule/index.vue'),
		},
		// 设备管理 >> 设备锁机
		{
			path: 'logked',
			name: 'logked',
			component: () => import('@/views/propertyManage/equipmentManage/lockMachine/index.vue'),
		},
		// 设备管理 >> 设备基础数据
		{
			path: 'equipmentData',
			name: 'equipmentData',
			component: () =>
				import('@/views/propertyManage/equipmentManage/equipmentData/equipmentData.vue'),
		},
		// 设备清单 -hh
		{
			path: 'equipmentList',
			name: 'equipmentList',
			component: () => import('@/views/propertyManage/equipmentList/equipmentList.vue'),
		},

		//设备清单编辑页 -hh
		{
			path: 'deviceManagement',
			name: 'deviceManagement',
			component: () => import('@/views/propertyManage/equipmentList/deviceManagement.vue'),
		},

		// 设备库存  -hh
		{
			path: 'deviceStorage',
			name: 'deviceStorage',
			component: () => import('@/views/propertyManage/equipmentList/deviceStorage.vue'),
		},
		// 替租设置
		{
			path: 'rentSet',
			name: 'rentSet',
			component: () => import('@/views/propertyManage/equipmentList/rentSet.vue'),
		},
		// 设备报停
		{
			path: 'stopReport',
			name: 'stopReport',
			component: () =>
				import('@/views/propertyManage/equipmentManage/stopReport/index/index.vue'),
		},

		// 物流管理 -- 物流需求单
		{
			path: 'LogisticsOrder',
			name: 'LogisticsOrder',
			component: () => import('@/views/propertyManage/logisticsManage/logistics/index.vue'),
		},

		//物流管理>>处理物流需求单 -hh
		// {
		// 	path: 'ProcessLogisticsOrder',
		// 	name: 'ProcessLogisticsOrder',
		// 	component: () =>
		// 		import(
		// 			'@/views/propertyManage/logisticsManage/logistics/ProcessLogisticsOrder.vue'
		// 		),
		// },
		// 物流管理>>处理物流需求单详情页 -hh
		// {
		// 	path: 'demandSheetDetails',
		// 	name: 'demandSheetDetails',
		// 	component: () =>
		// 		import(
		// 			'@/views/propertyManage/logisticsManage/logistics/demandSheetDetails.vue'
		// 		),
		// },
		// 配件管理-配件基础 -hh
		{
			path: 'partsManage',
			name: 'partsManage',
			component: () => import('@/views/propertyManage/partsManage/partsManage.vue'),
		},

		// （仓库管理员）-领用记录 -hh
		{
			path: 'requisitionRecord',
			name: 'requisitionRecord',
			component: () =>
				import(
					'@/views/propertyManage/mountingsReceiveSendBack/warehouseManage/requisitionRecord.vue'
				),
		},
		// 设备管理 >> 标准租金设置 -hh
		{
			path: 'renSet',
			name: 'renSet',
			component: () => import('@/views/propertyManage/equipmentManage/rentSet/rentSet.vue'),
		},
		// 物流公司
		{
			path: 'logisticsCompany',
			name: 'logisticsCompany',
			component: () =>
				import('@/views/propertyManage/logisticsManage/logisticsCompany/index.vue'),
		},

		// (配件领用)
		{
			path: 'engRecord',
			name: 'engRecord',
			component: () =>
				import(
					'@/views/propertyManage/mountingsReceiveSendBack/engineerRecord/receiveRecord/receiveRecord.vue'
				),
		},
		// (配件退回)
		{
			path: 'engBack',
			name: 'engBack',
			component: () =>
				import(
					'@/views/propertyManage/mountingsReceiveSendBack/engineerRecord/sendBackRecord/sendBackRecord.vue'
				),
		},
		// 配件领用与退还 ==> 个人库存
		{
			path: 'perInvertory',
			name: 'perInvertory',
			component: () =>
				import(
					'@/views/propertyManage/mountingsReceiveSendBack/personInventory/personInventory.vue'
				),
		},
		// 配件领用与退还 ==> 配件核销记录
		{
			path: 'partsWriteOffRecord',
			name: 'partsWriteOffRecord',
			component: () =>
				import(
					'@/views/propertyManage/mountingsReceiveSendBack/partsWriteOffRecord/index.vue'
				),
		},
		// 外采配件核销
		{
			path: 'outPartsCheck',
			name: 'outPartsCheck',
			component: () =>
				import('@/views/propertyManage/mountingsReceiveSendBack/outPartsCheck/index.vue'),
		},

		// 仓库管理
		{
			path: 'warehouseList',
			name: 'warehouseList',
			component: () => import('@/views/propertyManage/warehouseManage/warehouseList.vue'),
		},
		// 配件库存
		{
			path: 'accessoryInventory',
			name: 'accessoryInventory',
			component: () =>
				import('@/views/propertyManage/warehouseManage/accessoryInventory/index.vue'),
		},

		// 出库管理
		{
			path: 'warehouseOut',
			name: 'warehouseOut',
			component: () =>
				import('@/views/propertyManage/warehouseManage/warehouseOut/index.vue'),
		},
		// 入库管理
		{
			path: 'warehouseIn',
			name: 'warehouseIn',
			component: () => import('@/views/propertyManage/warehouseManage/warehouseIn/index.vue'),
		},
		// 检修管理
		{
			path: 'inspectionReportList',
			name: 'inspectionReportList',
			component: () =>
				import(
					'@/views/propertyManage/equipmentMaintenance/inspectionReport/inspectionReportList.vue'
				),
		},
		// 报修管理
		{
			path: 'repairsMange',
			name: 'repairsMange',
			component: () =>
				import(
					'@/views/propertyManage/equipmentMaintenance/repairsMange/repairsMangeList.vue'
				),
		},
		// 维修中心
		{
			path: 'repairsCentre',
			name: 'repairsCentre',
			component: () =>
				import('@/views/propertyManage/equipmentMaintenance/repairsCentre/index.vue'),
		},
		// 索赔管理
		{
			path: 'compensateMange',
			name: 'compensateMange',
			component: () =>
				import('@/views/propertyManage/compensateMange/compensateCenter/index.vue'),
		},
		// 索赔驳回未处理
		{
			path: 'compensatePendingHandle',
			name: 'compensatePendingHandle',
			component: () =>
				import(
					'@/views/propertyManage/compensateMange/compensateCenter/childPage/compensatePendingHandle.vue'
				),
		},
		// 设备修改仓库
		{
			path: 'modifyWarehouse',
			name: 'modifyWarehouse',
			component: () =>
				import(
					'@/views/propertyManage/warehouseManage/modifyWarehouse/modifyWarehouse.vue'
				),
		},
		// 维修设置
		{
			path: 'maintainSet',
			name: 'maintainSet',
			component: () =>
				import('@/views/propertyManage/equipmentMaintenance/maintainSet/index.vue'),
		},
		// 巡检规则
		{
			path: 'inspectionRules',
			name: 'inspectionRules',
			component: () =>
				import('@/views/propertyManage/equipmentMaintenance/inspectionRules/index.vue'),
		},
		// 巡检记录
		{
			path: 'inspectionLog',
			name: 'inspectionLog',
			component: () =>
				import('@/views/propertyManage/equipmentMaintenance/inspectionLog/index.vue'),
		},
		// 巡检管理
		{
			path: 'inspectionManage',
			name: 'inspectionManage',
			component: () =>
				import('@/views/propertyManage/equipmentMaintenance/inspectionManage/index.vue'),
		},
		// 低值易耗品
		{
			path: 'lowGoodsAccount',
			name: 'lowGoodsAccount',
			component: () =>
				import('@/views/propertyManage/lowGoodsManage/lowGoodsAccount/index.vue'),
		},

		//保养报告
		{
			path: 'maintainReport',
			name: 'maintainReport',
			component: () => import('@/views/propertyManage/maintainReport/index.vue'),
		},
		// 保养规则
		{
			path: 'maintainRules',
			name: 'maintainRules',
			component: () =>
				import('@/views/propertyManage/equipmentMaintenance/maintainRules/index.vue'),
		},
		// 保单列表
		{
			path: 'insuranceList',
			name: 'insuranceList',
			component: () =>
				import('@/views/propertyManage/insuranceManage/insuranceList/index.vue'),
		},
		// 过保保单列表
		{
			path: 'insuranceExceed',
			name: 'insuranceExceed',
			component: () =>
				import('@/views/propertyManage/insuranceManage/insuranceExceed/index.vue'),
		},
		// 过保保单列表
		{
			path: 'insuranceCostSet',
			name: 'insuranceCostSet',
			component: () =>
				import('@/views/propertyManage/insuranceManage/insuranceCostSet/index.vue'),
		},
		// 工具管理
		{
			path: 'toolList',
			name: 'toolList',
			component: () => import('@/views/propertyManage/toolManage/toolList/index.vue'),
		},
		// 设备区域统计
		{
			path: 'equipmentStatistics',
			name: 'equipmentStatistics',
			component: () =>
				import('@/views/propertyManage/equipmentManage/equipmentStatistics/index.vue'),
		},
		// 设备处置
		{
			path: 'equipmentDispose',
			name: 'equipmentDispose',
			component: () =>
				import('@/views/propertyManage/equipmentManage/equipmentDispose/index.vue'),
		},
		// 紧急配件处理
		{
			path: 'exigencyParts',
			name: 'exigencyParts',
			component: () => import('@/views/propertyManage/partsManage/exigencyParts/index.vue'),
		},
		// 调拨管理
		{
			path: 'allotList',
			name: 'allotList',
			component: () => import('@/views/propertyManage/allot/allotList/index.vue'),
		},
	],
}
